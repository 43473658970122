import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
// import AboutFour from "@/components/about-four";
// import VideoOne from "@/components/video-one";
// import TestimonialsOne from "@/components/testimonials-one";
// import TeamOne from "@/components/team-one";
import PrivacyPolicy from "@/components/privacy-policy";
// import AboutTwo from "@/components/about-two";
import { Helmet as Head } from "react-helmet";

const About = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Page">
        <Head>
          <title>Privacy Policy | Nifixeci</title>
          <meta
            name="description"
            content="This document gives you a thorough idea about the way Nifixeci uses
            the personal information of our Website visitors."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Privacy Policy" name="Privacy Policy" />
        {/* <AboutFour /> */}
        {/* <VideoOne /> */}
        <PrivacyPolicy />
        {/* <TestimonialsOne /> */}
        {/* <TeamOne extraClassName="section_border" /> */}
        {/* <SponsorOne /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default About;

import React from "react";
// import Img from "./img";
// import Link from "@/components/link";

const PrivacyPolicy = () => {
  // const { image, date, author, commentCount, title, url, text } = data;

  // const style = { textAlign: "justify" };

  return (
    <div
      // className="blog_share_box"
      style={{ padding: "80px 50px" }}
    >
      <div className="blog_share_details">
        <h1>Privacy Policy of Nifixeci</h1>
        <p>
          At Nifixeci, we value our customers and website visitors' privacy very
          much. As such, this Privacy Policy describes how we collect, use, and
          secure your personal information in accordance with the Hong Kong's
          Personal Data (Privacy) Ordinance.
        </p>
        <br />
        <h4>1. Collection of Personal Information</h4>
        <p>
          We collect personal information in order for us to serve you better in
          the pest control service as well as to enhance our interaction with
          you. This will include:
        </p>
        <li>Full name.</li>
        <li>Contact information such as email, phone, and address.</li>
        <li>The preference of the services and pest control needed.</li>
        <li>Payment information if any.</li>
        <p>We collect information through the following means such as:</p>
        <li>Forms filled on the website.</li>
        <li>Phone or email from you.</li>
        <li>Visiting your premises upon request for services offered.</li>
      </div>
      <div className="blog_share_details">
        <h4>2. Use of Personal Information</h4>
        <p>
          The personal information we collect from you is used for the following
          purpose(s):
        </p>
        <li>To offer you an exclusive pest control service.</li>
        <li>
          To be able to get in touch with you concerning your appointments,
          queries, or updates.
        </li>
        <li>Processing of the payments of services rendered.</li>
        <li>
          Improvement of functionality of our website and customer service.
        </li>
        <li>
          Sending promotional offers and updates only if you have opted-in.
        </li>
        <p>
          We shall not use your personal data for other purposes without
          explicit consent, except in cases provided for by law.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>3. Protection of Personal Information</h4>
        <p>
          Nifixeci takes your personal data security very seriously. We
          implement reasonable security measures to protect against unauthorized
          access, use, alteration, or disclosure of information. These include:
        </p>
        <li>Servers that store your information securely.</li>
        <li>
          Encryption methods for the data of a sensitive nature, like the
          payment information.
        </li>
        <li>
          Regular system checks in order to make sure that up-to-date data
          security measures are implemented.
        </li>
        <p>
          However, no data transmission over the internet can be entirely
          secure. We try to provide protection for your personal information,
          but cannot guarantee absolute security.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>4. Sharing of Personal Information</h4>
        <p>
          Nifixeci does not sell, trade, or transfer your personal information
          to third parties without consent. We may share your personal data with
          trusted service providers who assist us in operating our website or
          conducting business, provided they agree to keep this information
          confidential.
        </p>
        <p>
          We can also disclose personal information if ordered by the law or to
          protect legal rights.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>5. Your Rights</h4>
        <p>As a user of Nifixeci's services, you have the right to:</p>
        <li>
          Know and access the information we have about you, and request a copy.
        </li>
        <li>Rectify inaccuracies in your personal information.</li>
        <li>
          Request deletion or stop using your personal data, as per legal
          requirements.
        </li>
        <p>
          To exercise any of the foregoing rights or for other inquiries about
          your information, please contact us at info@nifixeci.com.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>6. Retention of Personal Information</h4>
        <p>
          We retain your personal data only for a period necessary to fulfill
          the purposes outlined in this policy or as long as the law dictates.
          When your data is no longer needed, we securely delete or anonymize
          it.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>7. Changes to the Privacy Policy</h4>
        <p>
          Nifixeci is entitled to change this Privacy Policy in cases where it
          is deemed necessary, either by changes in legislation or improvements
          in our services. We recommend checking back on this policy
          periodically so you can stay up to date as to how we protect your
          personal information.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>8. Contact Us</h4>
        <p>
          Should you have any questions or concerns about this Privacy Policy or
          how we use your personal information, please do not hesitate to
          contact us via info@nifixeci.com.
        </p>
        <p>
          This Privacy Policy is in compliance with the Personal Data (Privacy)
          Ordinance of Hong Kong and comes into effect from the date of posting.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
